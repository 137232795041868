import React from "react";
import { Bar, Line, Radar, Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const getDefaultOptions = (type, showGrid, barLabels) => {
  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  switch (type) {
    case "bar":
      return {
        ...commonOptions,
        plugins: {
          datalabels: {
            display: true, // Enable labels inside bars
            align: "center", // Align text inside the bar
            anchor: "center", // Position in the center of the bar
            color: "black", // Customize text color
            font: {
              weight: "bold",
            },
            formatter: function (value, context) {
              // Show both the custom label and the value inside the bar
              return barLabels ? barLabels[context.dataIndex].label : ""; // This will display the custom label inside the bar
            },
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            display: true,
            position: "bottom", // Keep the legend at the bottom
            labels: {
              fontSize: 10, // Reduce the font size for legends
              padding: 20, // Add more padding between legend items
              boxWidth: 15, // Smaller color boxes in the legend
            },
          },
          // this is the id that is specified below
          legendDistance: {
            padding: 500, // dictates the space
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: showGrid ? showGrid : false,
            },
            ticks: {
              display: true,
              color: "black",
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: showGrid ? showGrid : false,
            },
            ticks: {
              display: true,
              color: "black",
            },
          },
        },
      };
    case "line":
      return {
        ...commonOptions,
        plugins: {
          datalabels: {
            color: "#000",
            formatter: (value, context) => {
              const label = value?.label ? value?.label : "";
              return `${label}`;
            },
            anchor: "center", // Center the labels
            align: "center", // Align to the middle of the slices
            offset: 5,
            padding: 5, // Add padding to give space to labels
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: showGrid ? showGrid : false,
            },
            ticks: {
              display: true,
              color: "black",
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: showGrid ? showGrid : false,
            },
            ticks: {
              display: true,
              color: "black",
            },
          },
        },
        elements: {
          line: {
            tension: 0.4, // Curved lines
          },
        },
      };
    case "radar":
      return {
        ...commonOptions,
        scales: {
          r: {
            angleLines: {
              display: false,
            },
          },
        },
      };
    case "doughnut":
      return {
        plugins: {
          datalabels: {
            color: "#000",
            formatter: (value, context) => {
              const label = value?.label ? value?.label : "";
              return `${label}`;
            },
            anchor: "center", // Center the labels
            align: "center", // Align to the middle of the slices
            offset: 5,
            padding: 5, // Add padding to give space to labels
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            display: true,
            position: "bottom", // Keep the legend at the bottom
            labels: {
              fontSize: 10, // Reduce the font size for legends
              padding: 20, // Add more padding between legend items
              boxWidth: 15, // Smaller color boxes in the legend
            },
          },
          // this is the id that is specified below
          legendDistance: {
            padding: 500, // dictates the space
          },
        },
        layout: {
          padding: {
            top: 30, // Add space between the chart and the top
            bottom: 50, // Create more space between the chart and the legend
          },
        },
        responsive: true,
        maintainAspectRatio: true, // Maintain aspect ratio for a proportional chart
        cutout: "80%", // Increase the cutout size to make the chart smaller
        elements: {
          arc: {
            borderWidth: 2, // Keep a thinner border around the chart arcs
          },
        },
      };
    case "pie":
      // No specific options needed for doughnut or pie, using common options
      return {
        ...commonOptions,
        plugins: {
          datalabels: {
            color: "#000",
            formatter: (value, context) => {
              const label = value?.label ? value?.label : "";
              return `${label}`;
            },
            anchor: "center", // Center the labels
            align: "center", // Align to the middle of the slices
            offset: 5,
            padding: 5, // Add padding to give space to labels
          },
          legend: {
            display: true,
            position: "bottom", // Keep the legend at the bottom
            labels: {
              fontSize: 10, // Reduce the font size for legends
              padding: 20, // Add more padding between legend items
              boxWidth: 15, // Smaller color boxes in the legend
            },
          },
        },
        layout: {
          padding: {
            top: 30, // Add space between the chart and the top
            bottom: 50, // Create more space between the chart and the legend
          },
        },
      };
    default:
      return commonOptions;
  }
};

const getDefaultColors = (data) => {
  const colors = {
    backgroundColor: [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ],
    borderColor: [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ],
    borderWidth: 1,
  };

  return {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      ...colors,
    })),
  };
};

const MarkdownChartComponent = ({ type, data, labels, showGrid }) => {
  try {
    if (!type || !data) return null;
    const chartData = getDefaultColors({
      labels: labels ?? [],
      datasets: data.datasets,
    });

    const options =
      data.datasets[0].data[0]?.label && type.toLowerCase() === "bar"
        ? getDefaultOptions(type.toLowerCase(), showGrid, data.datasets[0].data)
        : getDefaultOptions(type.toLowerCase(), showGrid, null);

    switch (type.toLowerCase()) {
      case "bar":
        // Extract values (for bar height) and labels (for display inside the bars)
        const values = data.datasets[0].data[0]?.label
          ? {
              datasets: [
                { data: data.datasets[0].data.map((item) => item.value) },
              ],
            }
          : data;
          const barChartData = getDefaultColors({
            labels: labels ?? [],
            datasets: values.datasets,
          });
        return (
          <div
            class="chart-container"
            style={{ width: "400px", height: "400px" }}
          >
            <Bar data={barChartData} options={options} />
          </div>
        );
      case "line":
        return (
          <div
            class="chart-container"
            style={{ width: "400px", height: "400px" }}
          >
            <Line data={chartData} options={options} />
          </div>
        );
      case "radar":
        return (
          <div
            class="chart-container"
            style={{ width: "400px", height: "400px" }}
          >
            <Radar data={chartData} options={options} />
          </div>
        );
      case "doughnut":
        return (
          <div
            class="chart-container"
            style={{ width: "400px", height: "400px" }}
          >
            <Doughnut data={chartData} options={options} />
          </div>
        );
      case "pie":
        return (
          <div
            class="chart-container"
            style={{ width: "400px", height: "400px" }}
          >
            <Pie data={chartData} options={options} />
          </div>
        );
      default:
        return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default MarkdownChartComponent;
