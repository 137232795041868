import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const getAnnualReportsByCompanyID = createAsyncThunk(
  "report/getAnnualReportsByCompanyID",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/annual/get_annual_reports_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      // if (!response.data.data.length) {
      //   notification.error({ message: response.data.message });
      // }
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getAnnualReportsSection = createAsyncThunk(
  "report/getAnnualReportsSection",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/annual/get_annual_reports_section/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return { data: response.data, id };
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
export const addAnnualReport = createAsyncThunk(
  "report/addAnnualReport",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/annual/add_annual_report", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully added report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateAnnualReport = createAsyncThunk(
  "report/updateAnnualReport",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/annual/add_annual_report`, params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const reorderReports = createAsyncThunk(
  "report/reorderReports",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put("/api/admin/annual/add_order", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully reordered reports" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "report/deleteReport",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/annual/delete_annual_report/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully deleted report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);