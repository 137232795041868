import React, { useState, useCallback } from "react";
import Flex from "components/shared-components/Flex";
import { Button, Row, Col, Card, Popover, Spin } from "antd";
import {
  DeleteOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import _ from "lodash";

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const Files = (props) => {
  const [loading, setLoading] = useState(false);
  const { handleDelete, handleAddFile, uploadedfileList } = props;

  return (
    <div className="tab-contant">
      <div className="justify-content-center align-items-center flex-wrap">
        <Row justify="center">
          <Col span={16}>
            <div class="upload-btn-wrapper mt-3">
              <div className="file-upload-part">
                {loading ? (
                  <div className={`loading text-center cover-content`}>
                    <Spin indicator={Icon} tip="Please wait" size="large" />
                  </div>
                ) : (
                  <>
                    <p className="ant-upload-drag-icon mb-0">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text ml-2 mb-0 d-block">
                      Drag files or Click here to upload
                      <br />
                      <span style={{ fontSize: 8 }}>
                        file size should not be greater then 10mb
                      </span>
                    </p>
                  </>
                )}
              </div>

              <input
                type="file"
                name="myfile"
                value=""
                onChange={handleAddFile}
                onDragEnter={(e) => {
                  e.target.parentElement.classList.add("highlight");
                }}
                onDragLeave={(e) => {
                  e.target.parentElement.classList.remove("highlight");
                }}
                onDropCapture={(e) => {
                  e.target.parentElement.classList.remove("highlight");
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="notes-tab-cards mt-3">
          <Row gutter={8}>
            {_.cloneDeep(uploadedfileList).map((file, index) => {
              return (
                <Col span={6}>
                  <Card type="inner">
                    <Flex
                      className="card-head"
                      alignItems="start"
                      justifyContent="between"
                    >
                      <div className="preview-image-container">
                        <span className="preview-file-name" title={file.name}>
                          {file.name}
                        </span>
                        <Button
                          type="text"
                          danger
                          className="d-block px-0"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            handleDelete(file, index);
                          }}
                        />
                      </div>
                    </Flex>
                    <div className="card-body-contant">
                      <div className="note-description">
                        <img
                          src={URL.createObjectURL(file)}
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (store) => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Files);
