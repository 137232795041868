import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, notification, Select, Row, Col } from "antd";
import APIstatus from "redux/constants/common";
import Files from "components/layout-components/files";
import { getCompanies } from "redux/toolkit/companies/companyThunk";
import ReactMarkdown from "react-markdown";
import { addAnnualReport } from "redux/toolkit/reports/reportThunk";
import { addQuarterlyReport } from "redux/toolkit/quarterlyReports/quarterlyReportThunk";
import { getLanguages } from "redux/toolkit/languages/languageThunk";
import { getContentCategories } from "redux/toolkit/contentCategory/contentCategoryThunk";
import _ from "lodash";
import { getComanyParticularTableByCompanyId } from "redux/toolkit/companyAddTable/companyAddTableThunk";
import MarkdownChartComponent from "./components/markdownChartComponent";

const { TextArea } = Input;

const rules = {
  language_id: [
    {
      required: true,
      message: "Please input your language",
    },
  ],
  category_id: [
    {
      required: true,
      message: "Please select category!",
    },
  ],
  selectImages: [
    {
      required: true,
      message: "Please select Images",
    },
  ],
  title: [
    {
      required: true,
      message: "Please input your title",
    },
  ],
  company_id: [
    {
      required: true,
      message: "Please select company",
    },
  ],
  markdownContent: [
    {
      required: true,
      message: "Please input your mark down content",
    },
  ],
  content_type: [
    {
      required: true,
      message: "Please select content type",
    },
  ],
  s3_file_path: [
    {
      required: true,
      message: "Please select company particular",
    },
  ],
};

const MarkdownComponent = (props) => {
  const [form] = Form.useForm();

  const [companyListLoader, setCompanyListLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [textAreaContent, setTextAreaContent] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [languageLoader, setLanguageLoader] = useState(false);
  const [seletedReportType, setSelectedReportType] = useState("yearly");
  const [selectedContentType, setSelectedContentType] = useState("");

  useEffect(() => {
    props.getCompanies();
    props.getLanguages();
    props.getContentCategories();
    setCompanyListLoader(true);
    setLanguageLoader(true);
  }, []);

  useEffect(() => {
    if (
      companyListLoader &&
      (props.getCompanyStatus.status === APIstatus.SUCCESS ||
        props.getCompanyStatus.status === APIstatus.FAILURE)
    ) {
      setCompanyListLoader(false);
    }
  }, [props.getCompanyStatus.status]);

  useEffect(() => {
    if (
      languageLoader &&
      (props.getLanguageStatus.status === APIstatus.SUCCESS ||
        props.getLanguageStatus.status === APIstatus.FAILURE)
    ) {
      setLanguageLoader(false);
    }
  }, [props.getLanguageStatus.status]);

  useEffect(() => {
    if (submitLoader && props.addReportRes.status === APIstatus.SUCCESS) {
      setSubmitLoader(false);
      form.setFieldsValue({ title: "", markdownContent: "" });
      setTextAreaContent("");
      setFileList([]);
      setSelectedReportType("yearly");
    } else if (
      submitLoader &&
      props.addReportRes.status === APIstatus.FAILURE
    ) {
      setSubmitLoader(false);
    }
  }, [props.addReportRes.status]);

  useEffect(() => {
    if (
      submitLoader &&
      props.addQuarterlyReportRes.status === APIstatus.SUCCESS
    ) {
      setSubmitLoader(false);
      form.setFieldsValue({ title: "", markdownContent: "" });
      setTextAreaContent("");
      setFileList([]);
      setSelectedReportType("quarterly");
    } else if (
      submitLoader &&
      props.addQuarterlyReportRes.status === APIstatus.SUCCESS
    ) {
      setSubmitLoader(false);
    }
  }, [props.addQuarterlyReportRes.status]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let formData = new FormData();
        let data = { ...values, images: _.cloneDeep(fileList), id: "" };
        for (let key in data) {
          if (key !== "reportType") {
            if (key === "images") {
              data[key].map((file) => {
                formData.append("images", file);
              });
            } else {
              formData.append(key, data[key]);
            }
          }
        }
        if (seletedReportType === "yearly") {
          props.addAnnualReport(formData);
        } else {
          props.addQuarterlyReport(formData);
        }
        setSubmitLoader(true);
      })
      .catch((error) => {
        notification.error({
          message: `Validation Failed: ${error}`,
        });
      });
  };

  const handleFileDelete = (file, id) => {
    const list = [...fileList];
    list.splice(id, 1);
    setFileList(list);
  };

  const handleAddFile = (event) => {
    setFileList([...fileList, event.target.files[0]]);
  };

  // Custom renderer for code blocks to handle charts
  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /chart/.exec(className || "");
      try {
        if (match) {
          // Parse the JSON data from the code block
          const chartData = JSON.parse(children);

          // Render the Chart component with the parsed data
          return (
            <MarkdownChartComponent
              type={chartData.type}
              data={chartData.data}
              labels={chartData.labels}
              showGrid={chartData.showGrid}
            />
          );
        }

        return inline ? (
          <code {...props}>{children}</code>
        ) : (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      } catch (error) {
        console.log(error);
        return (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      }
    },
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="register-form"
        onFinish={onSubmit}
      >
        <Row gutter={8}>
          <Col span={7}>
            <Form.Item
              name="company_id"
              label="Select Company"
              rules={rules.company_id}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={(value) => {
                  props.getComanyParticularTableByCompanyId(value);
                }}
                defaultValue=""
                style={{ width: "100%" }}
                options={
                  Array.isArray(props.getCompanyStatus.data) &&
                  props.getCompanyStatus.data.length
                    ? props.getCompanyStatus.data
                        .filter((e) => e.active)
                        .map((e) => {
                          return { value: e.id, label: e.script_name };
                        })
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="reportType" label="Report Type">
              <Select
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                defaultValue="yearly"
                style={{ width: "100%" }}
                onChange={(value) => {
                  setSelectedReportType(value);
                }}
                options={[
                  { value: "yearly", label: "Annual Report" },
                  { value: "quarterly", label: "Conference Call" },
                ]}
              />
            </Form.Item>
          </Col>
          {
            <>
              <Col span={3}>
                <Form.Item
                  name="content_type"
                  label="Content Type"
                  rules={rules.content_type}
                  hasFeedback
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedContentType(value);
                    }}
                    defaultValue=""
                    style={{ width: "100%" }}
                    options={[
                      { value: "markdown", label: "Markdown" },
                      { value: "table", label: "Table" },
                    ].map((e) => {
                      return { value: e.value, label: e.label };
                    })}
                  />
                </Form.Item>
              </Col>
              {selectedContentType === "table" ? (
                <Col span={6}>
                  <Form.Item
                    name="s3_file_path"
                    label="Select Company Particular"
                    rules={rules.s3_file_path}
                    hasFeedback
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      defaultValue=""
                      style={{ width: "100%" }}
                      options={
                        Array.isArray(props.comanyTableByCompanyId.data) &&
                        props.comanyTableByCompanyId.data.length
                          ? props.comanyTableByCompanyId.data.map((e) => {
                              return { value: e.s3_file_path, label: e.title };
                            })
                          : []
                      }
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </>
          }
          <Col span={3}>
            <Form.Item
              name="language_id"
              label="Language"
              rules={rules.language_id}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                defaultValue=""
                style={{ width: "100%" }}
                options={
                  Array.isArray(props.getLanguageStatus.data) &&
                  props.getLanguageStatus.data.length
                    ? props.getLanguageStatus.data.map((e) => {
                        return { value: e.id, label: e.language };
                      })
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="category_id"
              label="Content Category"
              rules={rules.category_id}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                defaultValue=""
                style={{ width: "100%" }}
                options={
                  Array.isArray(props.getContentCategoriesRes.data) &&
                  props.getContentCategoriesRes.data.length
                    ? props.getContentCategoriesRes.data.map((e) => {
                        return { value: e.category_id, label: e.category_name };
                      })
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={seletedReportType === "quarterly" ? 4 : 7}>
            <Form.Item
              name="title"
              label="Title"
              rules={rules.title}
              hasFeedback
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {selectedContentType !== "table" ? (
          <>
            <Files
              uploadedfileList={[...fileList]}
              handleDelete={handleFileDelete}
              handleAddFile={handleAddFile}
            />
            <Form.Item
              name="markdownContent"
              label="Markdown Content"
              dependencies={["markdownContent"]}
              rules={rules.markdownContent}
              hasFeedback
            >
              <Row gutter={8}>
                <Col span={12}>
                  <TextArea
                    rows={20}
                    onChange={(e) => {
                      setTextAreaContent(e.target.value);
                    }}
                    value={textAreaContent}
                  />
                </Col>
                <Col span={12}>
                  <ReactMarkdown
                    children={
                      textAreaContent?.replace
                        ? textAreaContent.replace(/\\`/g, "`")
                        : textAreaContent
                    }
                    className="result-html full-height text-editor-preview"
                    components={components}
                  />
                </Col>
              </Row>
            </Form.Item>
          </>
        ) : null}
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={submitLoader}>
            submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = ({
  company,
  annualReports,
  language,
  quarterlyReports,
  contentCategory,
  companyAddTable,
}) => {
  const { getCompanyStatus } = company;
  const { addReportRes } = annualReports;
  const { addQuarterlyReportRes } = quarterlyReports;
  const { getLanguageStatus } = language;
  const { getContentCategoriesRes } = contentCategory;
  const { comanyTableByCompanyId } = companyAddTable;
  return {
    getCompanyStatus,
    addReportRes,
    addQuarterlyReportRes,
    getLanguageStatus,
    getContentCategoriesRes,
    comanyTableByCompanyId,
  };
};

const mapDispatchToProps = {
  getCompanies,
  addAnnualReport,
  addQuarterlyReport,
  getLanguages,
  getContentCategories,
  getComanyParticularTableByCompanyId,
};

const MarkDown = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarkdownComponent);
export { MarkDown };
