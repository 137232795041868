import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { notification } from "antd";

export const getFeedbacks = createAsyncThunk(
  "feedbacks/getFeedbacks",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_feedback", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateFeedback = createAsyncThunk(
  "feedbacks/updateFeedback",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_feedback/${params.id}`, {
        is_feedback_handled: params.is_feedback_handled,
        admin_note: params.admin_note,
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
