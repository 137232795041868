import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addBackup,
  deleteBackup,
  getBackupsFromDatabase,
  downloadBackupDatabase,
} from "./backupsThunk";

const initialState = {
  addBackupRes: { status: "", data: undefined },
  deleteBackupRes: { status: "", data: undefined },
  getBackupsFromDatabaseRes: { status: "", data: [] },
  downloadBackupRes: { status: "", data: null },
};

export const backupSlice = createSlice({
  name: "backups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadBackupDatabase.pending.toString(), (state) => {
      return {
        ...state,
        downloadBackupRes: {
          status: APIstatus.IN_PROGRESS,
          data: null,
        },
      };
    });
    builder.addCase(
      downloadBackupDatabase.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          downloadBackupRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(downloadBackupDatabase.rejected.toString(), (state) => {
      return {
        ...state,
        downloadBackupRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getBackupsFromDatabase.pending.toString(), (state) => {
      return {
        ...state,
        getBackupsFromDatabaseRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getBackupsFromDatabase.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getBackupsFromDatabaseRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getBackupsFromDatabase.rejected.toString(), (state) => {
      return {
        ...state,
        getBackupsFromDatabaseRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addBackup.pending.toString(), (state) => {
      return {
        ...state,
        addBackupRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addBackup.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addBackupRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addBackup.rejected.toString(), (state) => {
      return {
        ...state,
        addBackupRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteBackup.pending.toString(), (state) => {
      return {
        ...state,
        deleteBackupRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteBackup.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteBackupRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteBackup.rejected.toString(), (state) => {
      return {
        ...state,
        deleteBackupRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
