import { createSlice } from "@reduxjs/toolkit";
import {
  addParticularInfoReq,
  fetchAllParticularInfoReq,
  filterParticularInfoReq,
} from "./particularInfoThunk";
import APIstatus from "redux/constants/common";

const initialState = {
  addParticularInfoRes: { status: "", data: null },
  fetchAllParticularInfoRes: { status: "", data: null },
  filterParticularInfoRes: {status: "", data: []},
};

export const particularInfoSlice = createSlice({
  name: "particularInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllParticularInfoReq.pending.toString(), (state) => {
      return {
        ...state,
        fetchAllParticularInfoRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(
      fetchAllParticularInfoReq.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          fetchAllParticularInfoRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(fetchAllParticularInfoReq.rejected.toString(), (state) => {
      return {
        ...state,
        fetchAllParticularInfoRes: { status: APIstatus.FAILURE, data: null },
      };
    });

    builder.addCase(addParticularInfoReq.pending.toString(), (state) => {
      return {
        ...state,
        addParticularInfoRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(addParticularInfoReq.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addParticularInfoRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addParticularInfoReq.rejected.toString(), (state) => {
      return {
        ...state,
        addParticularInfoRes: { status: APIstatus.FAILURE, data: null },
      };
    });

    builder.addCase(filterParticularInfoReq.pending.toString(), (state) => {
      return {
        ...state,
        filterParticularInfoRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(filterParticularInfoReq.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        filterParticularInfoRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(filterParticularInfoReq.rejected.toString(), (state) => {
      return {
        ...state,
        filterParticularInfoRes: { status: APIstatus.FAILURE, data: null },
      };
    });

  },
});

// export const {} = definitionSlice.actions;
