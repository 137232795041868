import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addBackup = createAsyncThunk(
  "backups/addBackup",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/db_backup", params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getBackupsFromDatabase = createAsyncThunk(
  "backups/getBackupsFromDatabase",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/list_db_backups_from_db", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const downloadBackupDatabase = createAsyncThunk(
  "backups/downloadBackupDatabase",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/download_backup/${id}`, {
        "public-request": "true",
        responseType: "blob",
      })
      .catch((e) => {
        return e;
      });
    if (response.status === 200 && response.data) {
      notification.success({ message: "backup downloaded successfully" });
      return response.data;
    } else {
      notification.error({ message: "backup download failed" });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteBackup = createAsyncThunk(
  "backups/deleteBackup",
  async (params, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_db_backup`, {
        params,
        headers: {
          "public-request": "true",
        },
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
