import {
  DashboardOutlined,
  HddOutlined,
  ApartmentOutlined,
  StarOutlined,
  AreaChartOutlined,
  PicLeftOutlined,
  GlobalOutlined,
  ProfileOutlined,
  UserOutlined,
  PieChartOutlined,
  AuditOutlined,
  UndoOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sectors",
    path: `${APP_PREFIX_PATH}/sectors`,
    title: "Sectors",
    icon: ApartmentOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "contentCategory",
    path: `${APP_PREFIX_PATH}/content-category`,
    title: "Content Category",
    icon: AuditOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "companyInfo",
    path: `${APP_PREFIX_PATH}/company-info`,
    title: "Company Info",
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "languages",
    path: `${APP_PREFIX_PATH}/languages`,
    title: "Languages",
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "definitions",
    path: `${APP_PREFIX_PATH}/definitions`,
    title: "Definitions",
    icon: ProfileOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "companies",
    path: `${APP_PREFIX_PATH}/companies`,
    title: "Companies",
    icon: HddOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "markdown",
    path: `${APP_PREFIX_PATH}/markdown`,
    title: "Markdown",
    icon: PicLeftOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "feedbacks",
    path: `${APP_PREFIX_PATH}/feedbacks`,
    title: "Feedbacks",
    icon: StarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "annualReports",
    path: `${APP_PREFIX_PATH}/annual-reports`,
    title: "Annual Reports",
    icon: AreaChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "quarterlyReports",
    path: `${APP_PREFIX_PATH}/quarterly-reports`,
    title: "Quarterly Reports",
    icon: AreaChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "particular_info",
    path: `${APP_PREFIX_PATH}/particular_info`,
    title: "Particular Info",
    icon: ProfileOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "add_table",
    path: `${APP_PREFIX_PATH}/add_table`,
    title: "Add Table",
    icon: TableOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "backups",
    path: `${APP_PREFIX_PATH}/backups`,
    title: "Backups",
    icon: UndoOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
