import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addSector = createAsyncThunk(
  "sector/addSector",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/sectors", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Sector added successfully` });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSectors = createAsyncThunk(
  "sector/getSectors",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/sectors", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateSector = createAsyncThunk(
  "sector/updateSector",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/sectors/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteSector = createAsyncThunk(
  "sector/deleteSector",
  async (params, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/sectors/${params.id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Sector deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
