import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addLanguage = createAsyncThunk(
  "language/addLanguage",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_language", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Language added successfully` });
      return response.data;
    } else {
      notification.error({ message: `Something went wrong!` });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "language/getLanguages",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_languages", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateLanguage = createAsyncThunk(
  "language/updateLanguage",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_language/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteLanguage = createAsyncThunk(
  "language/deleteLanguage",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_language/${id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Language deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
