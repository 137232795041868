import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addLanguage,
  getLanguages,
  updateLanguage,
  deleteLanguage,
} from "./languageThunk";

const initialState = {
  languageAddStatus: { status: "", data: undefined },
  getLanguageStatus: { status: "", data: [] },
  updateLanguageStatus: { status: "", data: undefined },
  deleteLanguageStatus: { status: "", data: undefined },
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addLanguage.pending.toString(), (state) => {
      return {
        ...state,
        languageAddStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addLanguage.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        languageAddStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addLanguage.rejected.toString(), (state) => {
      return {
        ...state,
        languageAddStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getLanguages.pending.toString(), (state) => {
      return {
        ...state,
        getLanguageStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getLanguages.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getLanguageStatus: { status: APIstatus.SUCCESS, data: action.payload.data },
      };
    });
    builder.addCase(getLanguages.rejected.toString(), (state) => {
      return {
        ...state,
        getLanguageStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateLanguage.pending.toString(), (state) => {
      return {
        ...state,
        updateLanguageStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateLanguage.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateLanguageStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateLanguage.rejected.toString(), (state) => {
      return {
        ...state,
        updateLanguageStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteLanguage.pending.toString(), (state) => {
      return {
        ...state,
        deleteLanguageStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteLanguage.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteLanguageStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteLanguage.rejected.toString(), (state) => {
      return {
        ...state,
        deleteLanguageStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
