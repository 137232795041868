class TokenService {
  saveToken = (token) => {
    localStorage.setItem("authToken", token);
  };
  getToken = () => {
    return localStorage.getItem("authToken");
  };
  deleteToken = () => {
    return localStorage.removeItem("authToken");
  };
}

export default new TokenService();
