import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const login = createAsyncThunk(
  "auth/login",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/login", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      const { access_token } = response.data.data;
      // Save the token to local storage
      localStorage.setItem("authToken", access_token);
      // Display success message
      notification.success({ message: response.data.message });
    } else {
      // Display error message
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const changeAdminPassword = createAsyncThunk(
  "auth/changeAdminPassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/change_password_admin", params, {
        "public-requrest": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

// export const signUp = createAsyncThunk(
//   "auth/signUp",
//   async (params, thunkAPI) => {
//     const response = await secureApi
//       .post("/api/admin/register", params, {
//         "public-request": "true",
//       })
//       .catch((e) => {
//         return e;
//       });
//     if (response.data.success) {
//       notification.success({ message: response.data.message });
//     } else {
//       notification.error({ message: response.data.message });
//     }
//   }
// );
