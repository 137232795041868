import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const fetchAllParticularInfoReq = createAsyncThunk(
  "company/fetchAllparticularInfoReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/get_all_particulars_info`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addParticularInfoReq = createAsyncThunk(
  "company/addParticularInfoReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/add_particular_info`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const filterParticularInfoReq = createAsyncThunk(
  "company/filterParticularInfoReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/filter_particular_info/`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
